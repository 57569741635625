import { useCallback, useEffect, useMemo, useState } from "react";
import { API } from "../utils/Api";
import { Job, JobStatus } from '../models/core';
import { DateTime } from "luxon";

function findLastSuccJob<T extends Job>(jobs: T[]): T | null {
    return jobs.find(job => job.status == JobStatus.SUCCESS) || null;
}

function findLastJob<T extends Job>(jobs: T[]): T | null {
    return jobs.sort((a, b) => (
        DateTime.fromISO(b.updated || b.created || '').diff(DateTime.fromISO(a.updated || a.created || '')).milliseconds
    ))[0] || null;
}

export default function useJob<T extends Job>(
    clientId: string,
    open: boolean,
    initJobs: T[]
): { lastJob: T | null, lastSuccJob: T | null, restart: (immediate: boolean) => void } {
    const [jobs, setJobs] = useState<T[]>(initJobs);
    const lastJob = useMemo(() => findLastJob(jobs), [jobs]);
    const lastSuccJob = useMemo(() => findLastSuccJob(jobs), [jobs]);

    // Used to run useEffect below
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        const fetch = async () => {
            const jobs = (await API.getJobs(clientId, 'cluster')).items as T[];
            setJobs(jobs);
            if (findLastJob(jobs)?.status == JobStatus.IN_PROGRESS
              || findLastJob(jobs)?.status == JobStatus.NEW
              || findLastJob(jobs)?.status == JobStatus.TERMINATING) {
                if (!interval) {
                    interval = setInterval(() => fetch().catch(console.error), 10000);
                }
            }
            else {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
                // onDone && onDone();
            }
        };
        if (open) {
            if (trigger >= 0) {
                fetch().catch(console.error);
            }
            else {
                if (!interval) {
                    setTimeout(() => fetch().catch(console.error), 1000);
                }
            }
        }
        return () => { interval && clearInterval(interval); };
    }, [clientId, open, trigger]);

    const restart = useCallback((immediate = true) => {
        // Change trigger value preserving sign (1<->2 or -1<->-2)
        setTrigger(immediate ? (trigger > 1 ? 1 : 2) : (trigger < -1 ? -1 : -2));
    }, [trigger]);

    return { lastJob, lastSuccJob, restart };
}
