import axios from "axios";
import { Client, Patient, Measure, Plan, Layout, Check, Visit, Job, AssignmentInfo, PatientVisit, SchedulableSlot, PatientTrackables } from "../models/core";
import { analytics } from "./analytics/zipAnalytics";

type NoId<T> = Omit<T, 'id'|'_id'>;
export type NoIdRels<T> = Omit<T, 'id'|'_id'|'clientId'|'patientId'>;

export interface InsertOneResult {
    acknowledged: boolean;
    insertedId: string;
}

export interface ListResponse<T> {
    count: number;
    items: T[];
}


class _API {
    readonly baseUrl: string;
    token: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
        this.token = '';
    }

    getClients = async (
        filter: { [k in keyof Client]?: string | { $in: string[] } } = {}, 
        sort: [string, number][] = []
      ) => {
        // Construct query string from filter
        const queryString = Object.entries(filter)
          .map(([key, value]) => {
            if (typeof value === 'object' && '$in' in value) {
              return `${key}=${value.$in.map(encodeURIComponent).join(',')}`;
            } else {
              return `${key}=${encodeURIComponent(value as string)}`;
            }
          })
          .concat(`sort=${encodeURIComponent(JSON.stringify(sort))}`)
          .join('&');

        return await this.get<ListResponse<Client>>(`clients?${queryString}`);
      };

    getClient = async (clientId: string) => await this.get<Client>('clients/' + clientId);
    createClient = async (client: NoId<Client>) => await this.post('clients', client);
    updateClient = async (client: Client) => await this.put('clients/' + client._id, client);
    deleteClient = async (clientId: string) => await this.delete('clients/' + clientId);

    getPlans = async (clientId: string) => await this.get<ListResponse<Plan>>(`clients/${clientId}/plans`);
    getPlan = async (clientId: string, planId: string) => await this.get<Plan>(`clients/${clientId}/plans/${planId}`);

    getPatients = async (clientId: string, query: [string, string][], limit = 10, offset = 0, sort: [string, number][] = []) =>
        await this.get<ListResponse<PatientVisit>>(`clients/${clientId}/patients?limit=${limit}&offset=${offset}&sort=${JSON.stringify(sort)}&${query.map(([k, v]) => k + '=' + encodeURIComponent(v)).join('&')}`);

    getPatient = async (clientId: string, patientId: string) =>
        await this.get<Patient>(`clients/${clientId}/patients/${patientId}`);

    createPatient = async (patient: NoId<Patient>) =>
        await this.post<InsertOneResult,NoId<Patient>>(`clients/${patient.clientId}/patients`, patient);

    updatePatient = async (patient: Patient) =>
        await this.put<Patient>(`clients/${patient.clientId}/patients/${patient._id}`, patient);

    deletePatient = async (clientId: string, patientId: string) =>
        await this.delete<Patient>(`clients/${clientId}/patients/${patientId}`);

    assignPatients = async (clientId: string, assignmentInfo: AssignmentInfo, query: [string, string][]) =>
        await this.put<AssignmentInfo>(`clients/${clientId}/assigns/0?${query.map(([k,v]) => k+'='+encodeURIComponent(v)).join('&')}`, assignmentInfo);

    getVisits = async (clientId: string, patientId?: string, query?:[string,string][]) => 
        await this.get<ListResponse<Visit>>(`clients/${clientId}/patients/${patientId}/visits?limit=10000&${(query||[]).map(([k,v]) => k+'='+encodeURIComponent(v)).join('&')}`);

    getVisit = async (clientId: string, patientId: string, visitId: string) =>
        await this.get<Visit>(`clients/${clientId}/patients/${patientId}/visits/${visitId}`);

    createVisit = async (visit: NoId<Visit>) =>
        await this.post(`clients/${visit.clientId}/patients/${visit.patientId}/visits`, visit);

    updateVisit = async (visit: Partial<Visit>) =>
        await this.put<Visit, Partial<Visit>>(`clients/${visit.clientId}/patients/${visit.patientId}/visits/${visit._id}`, visit);

    getMeasures = async (clientId: string, patientId?: string, visitId?: string) => 
        await this.get<ListResponse<Measure>>(
            `clients/${clientId}` 
            + (patientId ? `/patients/${patientId}` : '')
            + (visitId ? `/visits/${visitId}` : '')
            + '/measures?limit=10000')

    getMeasure = async (clientId: string, patientId: string, measureId: string) =>
        await this.get<Measure>(`clients/${clientId}/patients/${patientId}/measures/${measureId}`);

    createMeasure = async (measure: NoId<Measure>) =>
        await this.post(`clients/${measure.clientId}/patients/${measure.patientId}/visits/${measure.visitId}/measures`, measure);

    updateMeasure = async (measure: Measure) =>
        await this.put<Measure>(`clients/${measure.clientId}/patients/${measure.patientId}/visits/${measure.visitId}/measures/${measure._id}`, measure);

    deleteMeasure = async (clientId: string, patientId: string, visitId: string, measureId: string) =>
        await this.delete<Measure>(`clients/${clientId}/patients/${patientId}/visits/${visitId}/measures/${measureId}`);

    getChecks = async (clientId: string, patientId?: string, visitId?: string) => 
        await this.get<ListResponse<Check>>(
            `clients/${clientId}` 
            + (patientId ? `/patients/${patientId}` : '')
            + (visitId ? `/visits/${visitId}` : '')
            + '/checks?limit=10000')

    getCheck = async (clientId: string, patientId: string, checkId: string) =>
        await this.get<Check>(`clients/${clientId}/patients/${patientId}/checks/${checkId}`);

    createCheck = async (check: NoId<Check>) =>
        await this.post(`clients/${check.clientId}/patients/${check.patientId}/visits/${check.visitId}/checks`, check);

    updateCheck = async (check: Check) =>
        await this.put<Check>(`clients/${check.clientId}/patients/${check.patientId}/visits/${check.visitId}/checks/${check._id}`, check);

    deleteCheck = async (clientId: string, patientId: string, visitId: string, checkId: string) =>
        await this.delete<Check>(`clients/${clientId}/patients/${patientId}/visits/${visitId}/checks/${checkId}`);


    getPatientTrackables = async (clientId: string, patientId: string) =>
        await this.get<ListResponse<PatientTrackables>>(`clients/${clientId}/patients/${patientId}/trackables?limit=10000`);

    createPatientTrackables = async (trackable: NoId<PatientTrackables>) =>
        await this.post(`clients/${trackable.clientId}/patients/${trackable.patientId}/trackables`, trackable);

    updatePatientTrackables = async (trackable: PatientTrackables) =>
        await this.put<PatientTrackables>(`clients/${trackable.clientId}/patients/${trackable.patientId}/trackables/${trackable._id}`, trackable);

    import = async (clientId: string) =>
        await this.post(`clients/${clientId}/import`, { });

    previewSheet = async(layout: Layout) =>
        (await axios.post<{ name: string; columns: string[] }[]>(
            this.baseUrl+'sheets/preview', 
            JSON.stringify(layout), 
            {headers: {'Content-Type': 'application/json'}})
        ).data;

    createSheet = async(layout: Layout, email: string) =>
        (await axios.post<{sheetId: string}>(
            this.baseUrl+'sheets/create', 
            JSON.stringify({email, layout}), 
            {headers: {'Content-Type': 'application/json'}})
        ).data;

    testImportScript = async(code: string, sheetId: string) => 
        (await axios.post<string>(
            this.baseUrl+'scripts/test_import',
            { code, sheetId }
        )).data

    testExportScript = async(code: string, clientId: string) => 
        (await axios.post<string>(
            this.baseUrl+'scripts/test_export',
            { code, clientId }
        )).data

    export = async(code: string, sheetId: string, clientId: string) => 
        (await axios.post<string>(
            this.baseUrl+'scripts/export',
            { code, clientId, sheetId }
        )).data

    getJobs = async (clientId: string, type = '', sort: [string, number][] = []) =>
        await this.get<ListResponse<Job>>(`clients/${clientId}/jobs`
            + `?type=${type}`
            + (sort.length>0 ? `&sort=${JSON.stringify(sort)}` : '')
        )

    getJobsMeta = async (clientId: string, type = '', sort: [string, number][] = []) =>
        await this.get<ListResponse<Job>>(`clients/${clientId}/jobs/meta`
            + `?type=${type}`
            + (sort.length>0 ? `&sort=${JSON.stringify(sort)}` : '')
        )

    getJob = async (clientId: string, jobId: string) =>
        await this.get<Job>(`clients/${clientId}/jobs/${jobId}`)

    createJob = async <T extends Job>(job: NoId<T>) =>
        await this.post<InsertOneResult, NoId<T>>(
            `clients/${job.clientId}/jobs`,
            job
        )
    
    updateJob = async (job: Job) =>
        await this.put<Job>(`clients/${job.clientId}/jobs/${job._id}`, job);

    updateJobResult = async (job: Job, filter: {[k:string]: string}, data: unknown) =>
        await this.put<Job, unknown>(
            `clients/${job.clientId}/jobs/${job._id}/result?`+
            Object.entries(filter).map(([k, v]) => k + '=' + encodeURIComponent(v)).join('&'), 
            data
        );

    deleteJob = async (clientId: string, jobId: string) =>
        await this.delete<Job>(`clients/${clientId}/jobs/${jobId}`);

    runJob = async (clientId: string, job: Job|string) =>
        await this.post(
            `clients/${clientId}/jobs/${typeof(job)=='string' ? job : job._id}/run`,
            {}
        )

    terminateJob = async (clientId: string, job: Job) =>
        await this.post(
            `clients/${clientId}/jobs/${job._id}/terminate`,
            {}
        )
        
    getPatientValues = async(clientId: string, field: string) =>
        await this.get<string[]>(`clients/${clientId}/patients/values?field=${field}`);

    getSchedulableSlot = async(clientId: string, patientId: string) => 
        await this.get<SchedulableSlot>(`clients/${clientId}/schedulables/patient?patientId=${patientId}`);

    updateSchedulableSlots = async(clientId: string, slots:SchedulableSlot[]) => 
        await this.put<SchedulableSlot[]>(`clients/${clientId}/schedulables/0`, slots);

    saveSchedulableSlots = async(clientId: string, jobId:string, startTime:string, user:string, slotSize ='15') => 
        await this.put<SchedulableSlot[]>(`clients/${clientId}/schedulables/0?jobId=${encodeURIComponent(jobId)}`
            +`&startTime=${encodeURIComponent(startTime)}&user=${encodeURIComponent(user)}`
            +`&slotSize=${encodeURIComponent(slotSize)}`, []);

    async get<T>(path: string): Promise<T> {
        try {
            const response = (await axios.get<T>(
                this.baseUrl + path,
                { headers: { Authorization: this.token } }
            ))
            return response?.data;
        } catch(error) {
            console.error('Error thrown: ', error);

            if(axios.isAxiosError(error)) {
                const errorString = 'status: ' + error.response?.status + ' details: ' + error.response?.data;
                console.error(errorString);
                analytics.error('api-error', { error:errorString }, true);
            }
            throw error;
        }
    }

    async post<T, U = T>(path: string, data: U): Promise<T> {
        return (await axios.post<T>(
            this.baseUrl + path,
            JSON.stringify(data),
            {
                headers: {
                    'Authorization': this.token,
                    'Content-Type': 'application/json'
                }
            }
        )).data;
    }

    async put<T, U = T>(path: string, data: U): Promise<T> {
        return (await axios.put<T>(
            this.baseUrl + path,
            JSON.stringify(data),
            {
                headers:
                {
                    'Authorization': this.token,
                    'Content-Type': 'application/json'
                }
            }
        )).data;
    }

    async delete<T>(path: string): Promise<void> {
        await axios.delete<T>(
            this.baseUrl + path,
            { headers: { Authorization: this.token } }
        );
    }
}

export const API = new _API(process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5005/');