import * as React from 'react';
import { useState } from 'react';
import { /*redirect, ActionFunctionArgs,*/ LoaderFunctionArgs, 
    Outlet, useLoaderData, useNavigate, useLocation } from 'react-router-dom';
import AppBarPage from '../components/AppBarPage';
import { CircularProgress, Grid, IconButton, List, ListItemSecondaryAction, ListItemText, Menu, 
    MenuItem, Pagination, Tooltip, ListItemIcon, Paper,
    Box,
    Typography,} from '@mui/material';
import { API } from '../utils/Api';
import { Client, ClusterJob, Job, JobStatus, Patient, Plan, ScheduleJob, Visit } from '../models/core';
//import useSchedule from '../hooks/useSchedule';
import { DateTime } from 'luxon';
import useSearchParamsDict from '../hooks/useSearchParamsDict';
import useDialog from '../hooks/useDialog';
import { useAuth } from '../hooks/useAuth';
import { useDebouncedEffect } from '../hooks/useDebouncedEffect';
import PatientEditor from '../components/PatientEditor';
import SpinnerButton from '../components/SpinnerButton';
import SessionStorage from '../utils/SessionStorage';
import { analytics } from '../utils/analytics/zipAnalytics';
import { Check, PersonAdd, MoreVert, Schedule as ScheduleIcon, Workspaces as WorkspacesIcon, AssignmentInd as AssignmentIcon } from '@mui/icons-material';
import ClusterDialog from '../components/ClusterDialog';
import ScheduleDialog from '../components/ScheduleDialog';
import AssignmentDialog from '../components/AssignmentDialog'
import { PatientListItem } from '../components/PatientListItem';
import { AuthData } from '../models/ziphy';
import { useLoading } from '../hooks/useLoading';
import PatientFilterPanel, {FilterFields} from '../components/patients/PatientFilterPanel'
import { EnumField } from '../components/fields';

type LoaderData = [Client, (Patient & { visit?: Visit })[], number, Plan[], string, Job[], URLSearchParams, string[]];


export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<LoaderData> => {

    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';

        if (!clientId) {
            throw Error('Missing clientId param');
        }
    }

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }

    const searchParams = new URL(request.url).searchParams;


    const ssCID = new SessionStorage<string>('clientId');
    const ssCData = new SessionStorage<Client>('clientData');
    const ssPlanData = new SessionStorage<Plan[]>('planData');
    const ssJobsMeta = new SessionStorage<Job[]>('jobMetaData');
    const ssAssignData = new SessionStorage<string[]>('assignData');

    let client:Client|null = null;
    let plans:Plan[]|null = null;
    let jobs:Job[]|null = null;
    let assignData:string[]|null = null;
    if(ssCID.get() === clientId) {
        client = ssCData.get();
        plans = ssPlanData.get();
        jobs = ssJobsMeta.get();
        assignData = ssAssignData.get();
    }

    if(!client) {
        client = await API.getClient(clientId).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();

            console.error('Error in client:', error);
            analytics.error('api_error', { error:error }, true);
            throw error;
        });
        ssCID.set(clientId);
        ssCData.set(client);
    }

    if(!plans) {
        plans = (await API.getPlans(clientId).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();

            console.error('Error in plans:', error);
            analytics.error('api_error', { error:error }, true);
            throw error;
        }))?.items || [];

        ssPlanData.set(plans);
    }

    if(!jobs) {
        jobs = (await API.getJobsMeta(clientId).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();

            console.error('Error in jobs:', error);
            analytics.error('api-error', { error:error }, true);
            throw error;
        }))?.items || [];

        ssJobsMeta.set(jobs);
    }

    if(!assignData) {
        assignData = (await API.getPatientValues(clientId, 'assignment.assignedTo').catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();

            console.error('Error in jobs:', error);
            analytics.error('api-error', { error:error }, true);
            throw error;
        })) || [];

        ssAssignData.set(assignData);
    }
    
    const sortParam = searchParams.get('sort');
    const { items: patients, count: numPats } = await API.getPatients(
        clientId,
        Array.from(searchParams.entries()).filter(([k]) => !['sort', 'page'].includes(k)),
        10,
        10 * (Number.parseInt(searchParams.get('page') || '1', 10) - 1),
        (sortParam && JSON.parse(sortParam)) || []
    ).catch((error) => {
        console.error('Error in patients:', error);
        analytics.error('api-error', { error:error }, true);
        throw error;
    });

    const repNames = new Set<string>();
    client?.userAccess?.forEach((e) => {    if(!repNames.has(e.name)) {  repNames.add(e.name); }   });
    client?.repNames?.forEach((e) => {    if(!repNames.has(e)) {  repNames.add(e); }   });
    assignData?.forEach((e) => {    if(!repNames.has(e)) {  repNames.add(e); }   });

    return [client, patients, numPats, plans, clientId, jobs as Job[], searchParams,Array.from(repNames)];
}

const PatientsPage = (): JSX.Element => {

    const [client, patients, count, plans, clientId, jobs, searchString, assignmentFilters] = useLoaderData() as LoaderData;
    
    const [searchParams, setSearchParams] = useSearchParamsDict<'page' | 'sort' | FilterFields>();
    const [filterParams, setFilterParams] = useState<Partial<Record<'page' | 'sort' | FilterFields, string>>>(searchParams);

    const [patientToEdit, setPatientToEdit] = useState<Patient | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const loading = useLoading();

    const page = React.useMemo(() => parseInt(searchParams.page || '1', 10), [searchParams]);
    
    const { open: peOpen, show: showPE, hide: hidePE } = useDialog();
    const { open: clOpen, show: showCl, hide: hideCL } = useDialog();
    const { open: a1dOpen, show: showA1D, hide: hideA1D } = useDialog();
    const { open: aMdOpen, show: showAMD, hide: hideAMD } = useDialog();
    const { open: scOpen, show: showSc, hide: hideSc } = useDialog();
    
    const [filtersSyncing, setFiltersSyncing] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [syncInProgress, setSyncInProgress] = useState(false);
    const [assignablePatient, setAssignablePatient] = useState<Patient|null>(null);

    const sortKeys:Record<string,string> = {
        'Name':'[]',
        'Zip':'[["zip",1]]'
    };

    const [sortType, setSortType] = useState<string>(
        findKeyByValue(sortKeys, searchParams?.sort||'') || 'Name'
    );

    function findKeyByValue(record: Record<string, string>, value: string): string | undefined {
        const sanitizedValue = value.trim().replace(/\s+/g, '');
        for (const [key, storedValue] of Object.entries(record)) {
            if (storedValue.replace(/\s+/g, '') === sanitizedValue) {
                return key;
            }
        }
        return undefined;
    }
    
    const auth = useAuth();

    useDebouncedEffect(() => {
        setSearchParams(filterParams);
    }, 300, [filterParams]);

    React.useEffect(() => { setFiltersSyncing(false); }, [searchString])
    React.useEffect(() => { loading.hideLoading(); }, []);

    const onPageChange = React.useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setFiltersSyncing(true);
        setSearchParams({ page: value + '' })
    }, [setSearchParams]);

    const onMoreClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onClusterClick = React.useCallback(() => {
        showCl();
        setAnchorEl(null);
    }, [showCl]);

    const onScheduleClick = React.useCallback(() => {
        showSc()
        setAnchorEl(null);
    }, [showSc]);
    const onSyncClick = React.useCallback(async () => {
        setSyncInProgress(true);
        analytics.track("user_sync", { clientId: clientId }, true);
        try {
            await API.import(clientId);
        }
        catch (e) {
            analytics.error('sync_failed', {}, true);
        } finally {
            setSyncInProgress(false);
        }
    }, [clientId]);

    /*const hideSchedule = React.useCallback(() => {
        setShowSchedule(false);
    }, []);*/

    const onEdit = React.useCallback((patient: Patient) => {
        setPatientToEdit(patient);
        showPE();
    }, [showPE]);

    const onPatientSaved = React.useCallback(() => {
        navigate(location.pathname + location.search, { replace: true });
        hidePE();
    }, [hidePE, navigate, location]);

    const onAddPatient = React.useCallback(() => {
        setPatientToEdit(null);
        showPE();
    }, [showPE]);

    const patientList = React.useMemo(() => {
            return (patients || []).map((patient) => (
                <PatientListItem
                    key={patient._id}
                    patient={patient}
                    visit={patient.visit || null}
                    onEdit={onEdit}
                    onAssign={(patient:Patient) => {
                            setAssignablePatient(patient);
                            showA1D();
                        }
                    }
                />
            ));
        },
        [patients, onEdit, setAssignablePatient, showA1D]
    );
    const clusterJobStatus = React.useMemo(() => {
        return jobs.find(job => job.name == 'cluster')?.status || '';
    }, [jobs]);

    const assignConfirmSingleDialog = React.useMemo<JSX.Element>(() => {
        const onHandleAssignUser = async (value:string) => {

            if(assignablePatient == null) {
                console.error('Patient not set for assignment');
                return;
            }
    
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignablePatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'single', patient:assignablePatient._id, to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.updatePatient({ ...assignablePatient, ...
                { assignment: { assignedDate: ts, assignedTo: value, assignedBy: assigner } }
            });
            
            setSearchParams({ page: page + '' });
            hideA1D();
        };

        return <AssignmentDialog open={a1dOpen} showIfBusy={true} client={client} 
            defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={hideA1D}/>
        
    },[a1dOpen, hideA1D, client, assignablePatient, auth?.user?.name, setSearchParams, page]);

    const assignConfirmQueryDialog = React.useMemo<JSX.Element>(() => {
        const onHandleAssignUser = async (value:string) => {
            
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignablePatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'many', to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.assignPatients(clientId, { assignedDate: ts, assignedTo: value, assignedBy:assigner },
                Array.from(searchString.entries()).filter(([k]) => !['sort', 'page'].includes(k))
            )

            setSearchParams({ page: page + '' });
            hideAMD();
        };

        return <AssignmentDialog open={aMdOpen} showIfBusy={true} client={client} 
            defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={hideAMD}/>

    },[aMdOpen, hideAMD, client, auth?.user?.name, clientId, searchString, setSearchParams, page,
        assignablePatient?.assignment?.assignedTo]);

    return (
        <AppBarPage title={client?.name || 'Patients'}
            actions={<>
            {
                client.layout.options.enableSyncInPatients ? 
                    <SpinnerButton showSpinner={syncInProgress} onClick={onSyncClick} color='inherit'>Sync</SpinnerButton>
                : <></>
            }
            <Tooltip id="tt-assign-all" title="Assign all currently filtered patients to a user.">
                <IconButton color='inherit' onClick={showAMD}><AssignmentIcon/></IconButton>
            </Tooltip>
            <Tooltip id="tt-add-patient" title="Add a new patient to the client.">
                <IconButton color='inherit' onClick={onAddPatient}><PersonAdd /></IconButton>
            </Tooltip>
            <Tooltip id="tt-on-more" title="More Options">
                <IconButton color='inherit' onClick={onMoreClick}><MoreVert /></IconButton>
            </Tooltip>
        </>
        }>

            <Grid container padding='2px 8px 0 8px'>
                <Grid item xs={3}>
                <Paper>
                <Grid item xs={12} padding='1vh 8px 1vh 8px'>
                    <EnumField name='en-sort' label='Sort By: ' value={sortType} 
                        onChange={(_,v) => {
                            const name = v as string ||'Name';
                            setSortType(name);
                            setFiltersSyncing(true);
                            setSearchParams({sort:sortKeys[name]||'[]'})
                        }}
                        options={Object.entries(sortKeys).map(v=>v[0])} required readOnly={false} />

                </Grid>
                </Paper>
                <Grid item xs={12} padding='0.25vh 8px 0.25vh 8px'></Grid>
                <Paper style={{minHeight:'79.5vh', maxHeight:'79.5vh', overflow: 'auto'}}>
                    <List>
                        <Grid item xs={12}>
                            <PatientFilterPanel client={client} plans={plans} syncing={filtersSyncing} 
                                searchParams={filterParams} onChange={(data) => {
                                        setFiltersSyncing(true);
                                        setFilterParams(data);
                                }} userAssignmentFilters={assignmentFilters}/>
                        </Grid>
                    </List>
                </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper style={{minHeight:'87vh', maxHeight:'87vh', overflow: 'auto'}}>
                        <List>
                            {!peOpen && patientList}
                        </List>
                    </Paper>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '12px', flexGrow: 1 }}>
                    <Typography>{count + ' Patients'}</Typography>
                    {
                        count > 0 ? <Pagination
                            page={page}
                            count={Math.ceil(count / 10)}
                            onChange={onPageChange}
                            
                            hidden={count < 10}
                        /> : <></>
                    }
                    </Box>
                </Grid>
                </Grid>
            </Grid>
            {peOpen ? <PatientEditor
                open={true} onClose={hidePE} onSave={onPatientSaved}
                patient={patientToEdit} client={client} plans={plans}/>
                : <></>}
            <Outlet />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onMenuClose}
                sx={{
                    '.MuiListItemText-root': {
                        paddingRight: '16px'
                    }
                }}
            >
                <MenuItem title='Roughly cluster visits' onClick={onClusterClick} >
                    <ListItemIcon><WorkspacesIcon /></ListItemIcon>
                    <ListItemText>Clusters</ListItemText>
                    <ListItemSecondaryAction>
                        {clusterJobStatus == JobStatus.IN_PROGRESS
                            ? <CircularProgress size={16} color='inherit' />
                            : clusterJobStatus == JobStatus.SUCCESS
                                ? <Check color='inherit' />
                                : null
                        }</ListItemSecondaryAction>
                </MenuItem>
                <MenuItem title='Build a detailed schedule' onClick={onScheduleClick}>
                    <ListItemIcon><ScheduleIcon /></ListItemIcon>
                    <ListItemText>Schedule</ListItemText>
                </MenuItem>
            </Menu>
            {clOpen
                ? <ClusterDialog
                    open
                    onClose={hideCL}
                    jobs={jobs.filter(j => j.name == 'cluster') as ClusterJob[]}
                    client={client}
                    plans={plans} />
                : ''
            }
            {scOpen
                ? <ScheduleDialog
                    open
                    onClose={hideSc}
                    jobs={jobs.filter(j => j.name == 'schedule') as ScheduleJob[]}
                    client={client}
                    plans={plans} />
                : ''}
            { a1dOpen ? assignConfirmSingleDialog : <></> }
            { aMdOpen ? assignConfirmQueryDialog : <></> }
        </AppBarPage>
    );
}

export default PatientsPage;
